import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
// import Progress from "../../components/progress";
import { useDispatch, useSelector } from "react-redux";

import { get, post } from "util/requests";
import { DataGrid } from "@mui/x-data-grid";
import { useBlocker, useNavigate } from "react-router-dom";
import styles from "./file_list_interface.module.css";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

import Header from "ui/header";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddIcon from "@mui/icons-material/Add";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";

import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import SelectedFileButtons from "ui/buttons/selected_file_buttons";
import UploadStatus from "ui/upload_status";
import moment from "moment";
import { handleFileAddedToQueue } from "ui/upload_status/helpers";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { arrayHaveCommonItems } from "util/helpers";

const theme = createTheme({
  mixins: {
    MuiDataGrid: {
      // Pinned columns sections
      pinnedBackground: "#340606",
      // Headers, and top & bottom fixed rows
      containerBackground: "#e9f1fd",
    },
  },
});

const FileUpload = () => {
  const [status, setStatus] = useState("");
  const [uploadTime, setUploadTime] = useState(0);
  const [data, setData] = useState([]);
  const [location, setLocation] = useState([
    {
      name: "RAISE",
      uuid: "e7e9546b-3a4a-4bd3-9ef9-f16e49249ffb",
      type: "parent",
    },
  ]);
  const [addFolderIsOpen, setAddFolderIsOpen] = useState(false);
  const [deleteAssetIsOpen, setDeleteAssetIsOpen] = useState(false);
  const [replaceModalIsOpen, setReplaceModalIsOpen] = useState(false);
  const [renameModalIsOpen, setRenameModalIsOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const file_select_ref = useRef(null);
  const dispatch = useDispatch();
  const refresh_file_list = useSelector(
    (state) => state.filesReducer.refresh_file_list,
  );
  const navigate = useNavigate();

  useBlocker(
    ({ currentLocation, nextLocation }) => {
      if (location.length <= 1) {
        return true;
      } else {
        fetch_path({}, "up");
        return true;
      }
    },
    // currentLocation.pathname !== nextLocation.pathname,
    // false,
  );

  const get_current_location_uuid = () => location.slice(-1)[0].uuid;

  const fetch_file_list = () =>
    get("/file_list", { params: { uuid: location.slice(-1)[0].uuid } }).then(
      (ret) => {
        setData(ret.data);
      },
    );
  useEffect(() => {
    fetch_file_list();
  }, [refresh_file_list]);

  const fetch_path = (obj, direction = "down") => {
    // console.log("start location", location);
    let _location;
    if (direction === "down") {
      _location = location;
      _location.push(obj);
    } else {
      // console.log("path", obj);
      // console.log("location", location);
      let index = location.findIndex((crumb) => crumb.uuid === obj.uuid);
      // console.log(index);
      if (index === 0 || index === -1) {
        _location = location.slice(0, location.length - 1);
      } else {
        _location = location.slice(0, index + 1);
      }
    }
    // console.log("location after", _location);
    setLocation(_location);
    setSelectedFiles([]);
    get("/file_data", { params: { uuid: _location.slice(-1)[0].uuid } }).then(
      (ret) => {
        setData(ret.data);
        setSelectedFiles([]);
      },
    );
  };

  const createNewFolder = (folder_name) => {
    let uuid = location.slice(-1)[0].uuid;
    post("/folder", { uuid, folder_name }).then((ret) => {
      let new_folder = ret.data;
      fetch_file_list();
      // setData([]);
      // let _location = [...location];
      // _location.push(new_folder);
      // console.log(_location);
      // setLocation(_location);
      setAddFolderIsOpen(false);
    });
  };

  const toggleFileSelection = (uuid, type, name) => {
    let is_selected = selectedFiles.map((sf) => sf.uuid).includes(uuid);
    let _selectedFiles = [...selectedFiles];
    if (is_selected) {
      _selectedFiles = _selectedFiles.filter((sf) => sf.uuid !== uuid);
    } else {
      _selectedFiles.push({ uuid, type, name });
    }
    setSelectedFiles(_selectedFiles);
  };

  const deleteAssets = () => {
    post("/delete_assets", {
      list: selectedFiles,
      parent_uuid: get_current_location_uuid(),
    }).then(() => {
      setDeleteAssetIsOpen(false);
      fetch_file_list();
      setSelectedFiles([]);
    });
  };

  const setQCStatus = (uuid, status) => {
    post(`/qc_status/${uuid}`, { uuid, status }).then(() => {
      let _data = [...data];
      let asset = _data.find((a) => a.uuid === uuid);
      asset.qc_status = status;
      setData(_data);
    });
  };

  return (
    <div className={styles.wrapper}>
      <Header />

      <div className={styles.table_wrapper}>
        <div className={styles.table_header}>
          <SelectedFileButtons
            selectedFiles={selectedFiles}
            setDeleteAssetIsOpen={setDeleteAssetIsOpen}
            setRenameModalIsOpen={setRenameModalIsOpen}
            blockDelete
            blockRename
          />
        </div>
        <div className={styles.table_container}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={data}
              columns={[
                {
                  field: "selected",
                  headerName: "",
                  width: 30,
                  headerClassName: "columnHeaders",
                  renderCell: ({ row }) => (
                    <div className={styles.circle_cell}>
                      {selectedFiles.map((sf) => sf.uuid).includes(row.uuid) ? (
                        <CircleIcon sx={{ fontSize: "14px" }} />
                      ) : (
                        <CircleOutlinedIcon sx={{ fontSize: "14px" }} />
                      )}
                    </div>
                  ),
                },

                {
                  field: "name",
                  headerName: "Name",
                  width: 400,
                  headerClassName: "columnHeaders",
                  renderCell: ({ row }) => (
                    <div>
                      <span
                        className={styles.folder_link}
                        onClick={() => {
                          if (
                            row.type === "folder" ||
                            row.type === "site_folder"
                          ) {
                            fetch_path({
                              name: row.name,
                              uuid: row.uuid,
                            });
                          }
                        }}
                      >
                        {" "}
                        {row.name}
                      </span>
                    </div>
                  ),
                },
                {
                  field: "site_folder",
                  headerName: "Parent Folder",
                  width: 400,
                  headerClassName: "columnHeaders",
                },
                {
                  field: "modified_on",
                  headerName: "Modified",
                  type: "number",
                  width: 150,
                  align: "center",
                  headerAlign: "center",
                  headerClassName: "columnHeaders",
                  renderCell: ({ row }) => (
                    <div>{moment(row.modified_on).format("MMMM DD, YYYY")}</div>
                  ),
                },
                {
                  field: "qc_status",
                  headerName: "QC Status",
                  type: "number",
                  width: 150,
                  align: "center",
                  headerAlign: "center",
                  headerClassName: "columnHeaders",
                  renderCell: ({ row }) => (
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => setQCStatus(row.uuid, !row.qc_status)}
                    >
                      {row.qc_status ? (
                        <CheckIcon
                          sx={{
                            fontSize: 18,
                            color: "#0067c2",
                          }}
                        />
                      ) : (
                        <CloseIcon
                          sx={{
                            fontSize: 18,
                            color: "#ddd",
                          }}
                        />
                      )}
                    </div>
                  ),
                },
              ]}
              disableColumnSelector
              disableRowSelectionOnClick
              disableColumnMenu
              hideFooterSelectedRowCount
              columnHeaderHeight={34}
              rowHeight={34}
              sx={{
                boxShadow: 0,
                border: 1,
                borderColor: "#f0f0f0",
                "& .MuiDataGrid-cell:hover": {
                  color: "#8349fd",
                  cursor: "pointer",
                },

                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: 700,
                  color: "#333",
                },
                "& .MuiDataGrid-cell:focus": { outline: "none" },
              }}
              onRowClick={(row) => {
                // if (row.row.type === "folder") {
                //   fetch_path({ name: row.row.name, uuid: row.row.uuid });
                // }
                toggleFileSelection(row.row.uuid, row.row.type, row.row.name);
              }}
              onCellClick={(cell) => {
                // if (cell.field === "name" && cell.row.type === "folder") {
                //   fetch_path({ name: cell.row.name, uuid: cell.row.uuid });
                // }
              }}
              hideFooterPagination
              hideFooterSelectedRowCount
              hideFooter
              slots={{
                noRowsOverlay: () => (
                  <div className={styles.no_rows_overlay}>Empty Folder</div>
                ),
              }}
            />
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
