import { Component } from "react";

import Routes from "./routes";

export default function BaseRoute() {
  // const { is_loading, showing_module_bar } = this.state;
  return (
    <div className="full_page">
      <Routes />
    </div>
  );
}
