import React, { useEffect, useRef } from "react";
import styles from "./header.module.css";
import { useSelector } from "react-redux";
import act_global_logo from "assets/basking_logo.jpg";
import AppsIcon from "@mui/icons-material/Apps";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { logout } from "util/auth";
import { useNavigate } from "react-router-dom";
import router from "views/routes";

export default function Header(props) {
  // const ref = useRef(null);
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.user.userData.permissions);
  console.log(permissions);

  return (
    <div className={styles.header_wrapper}>
      <div
        className={styles.hflex}
        onClick={() => {
          window.location = "/files";
        }}
      >
        <div className={styles.apps_icon_wrapper}></div>
        <img src={act_global_logo} height="40px" className={styles.logo} />
        <div className={styles.mulish_title}>Core Lab</div>
      </div>
      <div className={styles.settings_icons_wrapper}>
        {/*<SettingsOutlinedIcon
          sx={{
            color: "#555",
            fontSize: "26px",
            lineHeight: "26px",
            cursor: "pointer",
          }}
          onClick={() => {}}
        />*/}
        {permissions?.includes("corelab_qc") ? (
          <div
            className={styles.all_files_button}
            onClick={() => {
              console.log("clicked it");
              window.location = "/file_list";
            }}
          >
            All Files
          </div>
        ) : null}
        <div className={styles.logout} onClick={logout}>
          Logout
        </div>
      </div>
    </div>
  );
}
