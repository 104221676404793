import React, { Component } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import FolderInterface from "views/file_interface";
import FileInterface from "views/file_list_interface";

// const ProtectedRoute = (props) => {
//   const { permissions, required } = props;
//   return permissions.includes(required) ? (
//     <Outlet />
//   ) : (
//     <Navigate to={"/subjects"} />
//   );
// };

export default function Router() {
  // const user_data = useSelector((state) => state.user.userData);
  return (
    <Routes>
      <Route path="files" element={<FolderInterface />} />
      <Route path="file_list" element={<FileInterface />} />
    </Routes>
  );
}
